import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faTrafficLight } from '@fortawesome/free-solid-svg-icons';
import Header from "../Header/Header";
import plateImage from '../../img/pelak.png'; 
const Khalafiinp = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [nationalId, setNationalId] = useState('');
    const [platePart1, setPlatePart1] = useState(''); // بخش عددی اول پلاک (دو رقم)
    const [plateLetter, setPlateLetter] = useState(''); // حرف پلاک
    const [platePart2, setPlatePart2] = useState(''); // بخش عددی دوم پلاک (سه رقم)
    const [platePart3, setPlatePart3] = useState(''); // بخش عددی سوم پلاک (دو رقم)
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const[message, setMessage] = useState('');
    // لیست حروف و معادل عددی آن‌ها
    const letters = [
        { label: 'الف', value: '01' },
        { label: 'ب', value: '02' },
        { label: 'پ', value: '03' },
        { label: 'ت', value: '04' },
        { label: 'ث', value: '05' },
        { label: 'ج', value: '06' },
        { label: 'چ', value: '07' },
        { label: 'ح', value: '08' },
        { label: 'خ', value: '09' },
        { label: 'د', value: '10' },
        { label: 'ذ', value: '11' },
        { label: 'ر', value: '12' },
        { label: 'ز', value: '13' },
        { label: 'ژ', value: '14' },
        { label: 'س', value: '15' },
        { label: 'ش', value: '16' },
        { label: 'ص', value: '17' },
        { label: 'ض', value: '18' },
        { label: 'ط', value: '19' },
        { label: 'ظ', value: '20' },
        { label: 'ع', value: '21' },
        { label: 'غ', value: '22' },
        { label: 'ف', value: '23' },
        { label: 'ق', value: '24' },
        { label: 'ک', value: '25' },
        { label: 'گ', value: '26' },
        { label: 'ل', value: '27' },
        { label: 'م', value: '28' },
        { label: 'ن', value: '29' },
        { label: 'و', value: '30' },
        { label: 'ه', value: '31' },
        { label: 'ی', value: '32' },
        { label: 'تشریفات', value: '34' },
    ];

    // اعتبارسنجی شماره تلفن و کد ملی
    const validateInputs = () => {
        if (phoneNumber.length !== 11 || !/^\d+$/.test(phoneNumber)) {
            setError('شماره موبایل باید 11 رقم باشد.');
            return false;
        }
        if (nationalId.length !== 10 || !/^\d+$/.test(nationalId)) {
            setError('کد ملی باید 10 رقم باشد.');
            return false;
        }
        if (!platePart1 || !plateLetter || !platePart2 || !platePart3) {
            setError('لطفا تمام بخش‌های پلاک را وارد کنید.');
            return false;
        }
        return true;
    };

    // ارسال فرم
    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');

        if (!validateInputs()) return;

        setLoading(true);

        // ساخت شماره پلاک کامل
        const fullPlateNumber = `${platePart1}${plateLetter}${platePart2}${platePart3}`;

        // شبیه‌سازی ارسال داده‌ها به سرور
        setTimeout(() => {
            console.log('شماره موبایل:', phoneNumber);
            console.log('کد ملی:', nationalId);
            console.log('شماره پلاک:', fullPlateNumber);
            setLoading(false);
            setMessage('اطلاعات با موفقیت ارسال شد!');
        }, 1000);
    };

    return (
        <>
            <Header />
            <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen overflow-y-auto">
                <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52'>
                    <div className="md:col-span-7 md:p-4 rounded-md">
                        <span className='inline-block text-green-800 dark:text-green-200 text-lg ml-2'>
                            <FontAwesomeIcon icon={faTrafficLight} />
                        </span>
                        <h5 className="inline-flex items-center">استعلام خلافی خودرو</h5>
                        <h4 className="flex items-center justify-center">لطفا شماره موبایل، کد ملی و پلاک خودرو را وارد کنید.</h4>

                        <form className="flex flex-col items-center mt-4" onSubmit={handleSubmit} >
                            {/* شماره موبایل */}
                            <input
                                type="text"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9]/g, ''))}
                                placeholder="شماره موبایل (09123456789)"
                                maxLength={11}
                                className='border border-gray-300 rounded-md p-2 w-72 dark:bg-gray-700 dark:text-white mb-2'
                            />

                            {/* کد ملی */}
                            <input
                                type="text"
                                value={nationalId}
                                onChange={(e) => setNationalId(e.target.value.replace(/[^0-9]/g, ''))}
                                placeholder="کد ملی (0023315498)"
                                maxLength={10}
                                className='border border-gray-300 rounded-md p-2 w-72 dark:bg-gray-700 dark:text-white mb-2'
                            />

                            {/* پلاک خودرو */}
                            <div 
    className="flex gap-2 px-5 py-2 mt-2" 
    style={{ 
        direction: 'ltr', 
        backgroundImage: `url(${plateImage})`,
        backgroundSize: '100% 90%', // Stretch width
        backgroundPosition: '50% center', // Adjust this to move the image left or right
        backgroundRepeat: 'no-repeat', 
        width: '50%', // Desired width
        height: '90px', // Desired height
        padding: '5px', // Prevent clipping
    }}
>
    <input
        type="text"
        value={platePart1}
        onChange={(e) => setPlatePart1(e.target.value.replace(/[^0-9]/g, '').slice(0, 2))}
        placeholder="11"
        maxLength={2}
        style={{ background: 'none' }} // Make input background transparent
        className='border-2 border-gray-800 rounded-md p-2 w-16 dark:bg-gray-700 dark:text-white text-center ml-3'
    />
    <select
        value={plateLetter}
        onChange={(e) => setPlateLetter(e.target.value)}
        style={{ background: 'none' }} // Make select background transparent
        className='border-2 border-gray-800 rounded-md p-2 dark:bg-gray-700 dark:text-white text-center'
    >
        <option value="">انتخاب حرف</option>
        {letters.map((letter) => (
            <option key={letter.value} value={letter.value}>
                {letter.label}
            </option>
        ))}
    </select>
    <input
        type="text"
        value={platePart2}
        onChange={(e) => setPlatePart2(e.target.value.replace(/[^0-9]/g, '').slice(0, 3))}
        placeholder="323"
        maxLength={3}
        style={{ background: 'none' }} // Make input background transparent
        className='border-2 border-gray-800 rounded-md p-2 w-24 dark:bg-gray-700 dark:text-white text-center mr-6'
    />
    <input
        type="text"
        value={platePart3}
        onChange={(e) => setPlatePart3(e.target.value.replace(/[^0-9]/g, '').slice(0, 2))}
        placeholder="11"
        maxLength={2}
        style={{ background: 'none' }} // Make input background transparent
        className='border-2 border-gray-800 border-gray-900 rounded-md p-2 w-16 dark:bg-gray-700 dark:text-white text-center mr-[-25px] mt-3'
    />
</div>
                            {/* دکمه ارسال */}
                            <button
                                type="submit"
                                className='py-2 px-4 text-white bg-green-500 rounded w-72 mt-2 hover:bg-green-600 transition-all'
                                disabled={loading}
                            >
                                {loading ? 'در حال ارسال...' : 'ارسال'}
                            </button>

                            {/* نمایش خطا */}
                            {error && <div className="text-red-500 mt-2">{error}</div>}
                            {/* نمایش پیام وضعیت */}
                            {message && <div className="text-green-500 mt-2">{message}</div>}
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Khalafiinp;