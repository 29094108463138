import { useState, useEffect } from "react";
import Header from "../Header/Header";
import ReactPaginate from "react-paginate";
import { faCar, faClockRotateLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pelak from "../../img/pelak.png"; // تصویر پلاک خودرو
import noDataImage from "../../img/no-data.png"; // تصویر "داده‌ای وجود ندارد"

// داده mock به صورت ساختار JSON پیشنهادی
const mockResponse = {
  "responseCode": "FN-CRGO-20000100000",
  "trackId": "410a3431-526e-4a7f-92fd-af9bcdabf70f",
  "result": {
    "persons": [
      {
        "nationalId": "1234567890",
        "plateNumbers": [
          {
            "plateNumber": "ایران ۹۹ - ۲۸۳ ن ۸۰",
            "revoked": true,
            "revokedDateTime": "12/26/2018 13:23:40",
            "revokedDescription": "شماره گذاري بوکان",
            "serialNumber": "1010029296607",
            "date": "1402/01/01",
            "violations": [
              {
                "date": "1402/01/02",
                "type": "سرعت غیرمجاز",
                "amount": 200000
              },
              {
                "date": "1402/01/03",
                "type": "پارک غیرمجاز",
                "amount": 100000
              }
            ]
          },
          {
            "plateNumber": "ایران ۵۰ - ۱۵۹ ص ۸۰",
            "revoked": false,
            "revokedDateTime": null,
            "revokedDescription": null,
            "serialNumber": "10100448779921",
            "date": "1402/02/15",
            "violations": []
          }
        ]
      },
      {
        "nationalId": "9876543210",
        "plateNumbers": [
          {
            "plateNumber": "ایران ۸۸ - ۱۰۰ ب ۵۰",
            "revoked": false,
            "revokedDateTime": null,
            "revokedDescription": null,
            "serialNumber": "1010056789123",
            "date": "1402/03/20",
            "violations": [
              {
                "date": "1402/03/21",
                "type": "عدم رعایت ایمنی",
                "amount": 150000
              }
            ]
          }
        ]
      }
    ]
  },
  "status": "DONE"
};

function CarPlateHistory() {
  const [persons, setPersons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;
  const [totalPages, setTotalPages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlate, setSelectedPlate] = useState(null);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (mockResponse && mockResponse.result && mockResponse.result.persons) {
        setPersons(mockResponse.result.persons);
      }
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(persons.length / itemsPerPage));
  }, [persons]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleDelete = (nationalId, plateNumber) => {
    setPersons((prevPersons) =>
      prevPersons
        .map((person) => {
          if (person.nationalId === nationalId) {
            return {
              ...person,
              plateNumbers: person.plateNumbers.filter(
                (p) => p.plateNumber !== plateNumber
              )
            };
          }
          return person;
        })
        .filter((person) => person.plateNumbers.length > 0)
    );
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedPersons = persons.slice(startIndex, endIndex);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPlate(null);
  };

  return (
    <>
      <Header />
      <section className="dark:bg-stone-900 dark:text-gray-100 h-screen overflow-y-auto">
        <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52'>
          <div className="md:col-span-7 md:p-4 rounded-md">
            <span className='mr-4 text-green-800 dark:text-green-200 text-lg ml-2'>
              <FontAwesomeIcon icon={faClockRotateLeft} />
            </span>
            <h5 className="inline">تاریخچه استعلام‌های پلاک خودرو</h5>
            <div className="flex justify-end">
              {persons.length > itemsPerPage && (
                <ReactPaginate
                  pageCount={totalPages}
                  onPageChange={handlePageChange}
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  containerClassName={"pagination-container"}
                  pageLinkClassName={"page-link"}
                  activeLinkClassName={"active"}
                />
              )}
            </div>
            <div className="news-card grid grid-cols-1 gap-x-4 mt-2 px-2 overflow-y-auto dark:bg-stone-900 dark:text-gray-900">
              {loading ? (
                <p className='text-lg mt-2 pr-6'>در حال بارگذاری...</p>
              ) : persons.length === 0 ? (
                <div className="flex flex-col items-center py-10">
                  <img src={noDataImage} alt="داده‌ای وجود ندارد" className="w-32 h-32 mb-4" />
                  <p className="text-gray-500 dark:text-gray-400 text-lg">داده‌ای برای نمایش وجود ندارد.</p>
                </div>
              ) : (
                paginatedPersons.map((person, id) => (
                  <div key={id} className="w-full bg-light-green dark:bg-grey-100 hover:bg-grey-50 border rounded-md mb-3 p-3">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col text-white ">
                        <p className="text-sm">کد ملی: {person.nationalId}</p>
                        <p className="text-sm">تعداد پلاک‌های فعال: {person.plateNumbers.filter(p => !p.revoked).length}</p>
                      </div>
                      <div className="flex items-center gap-x-1 ">
                        {/* <button
                          onClick={() => {
                            setSelectedPlate({ ...person, nationalId: person.nationalId });
                            setShowModal(true);
                          }}
                          className="py-1 px-2 bg-green-500 text-white rounded hover:bg-blue-700"
                        >
                          مشاهده جزئیات
                        </button> */}
                        <button
                          onClick={() => handleDelete(person.nationalId, person.plateNumbers[0].plateNumber)}
                          className="py-1 px-2 bg-red-500 text-white rounded hover:bg-red-700 "
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    </div>
                    <div className="mt-2 ">
                      {person.plateNumbers.map((plate, idx) => (
                        <div key={idx} className="border p-2 rounded-md mb-2 bg-white dark:bg-gray-700">
                          <p className="text-sm">پلاک: {plate.plateNumber}</p>
                          <p className="text-sm">تاریخ استعلام: {plate.date}</p>
                          <p className="text-sm">وضعیت: {plate.revoked ? "غیرفعال" : "فعال"}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </section>

      {showModal && selectedPlate && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-md w-11/12 md:w-1/2">
            <h3 className="text-xl font-bold mb-4">جزئیات پلاک</h3>
            <p className="mb-2">کد ملی: {selectedPlate.nationalId}</p>
            <p className="mb-2">پلاک: {selectedPlate.plateNumber}</p>
            <p className="mb-2">تاریخ استعلام: {selectedPlate.date}</p>
            <p className="mb-2">وضعیت: {selectedPlate.revoked ? "محروم" : "فعال"}</p>
            {selectedPlate.revoked ? (
              <p className="text-red-600 font-bold mb-4">
                این پلاک محروم است. جزئیات خلافی‌ها نمایش داده نمی‌شود.
              </p>
            ) : (
              <>
                <div className="flex justify-center mb-4">
                  <img src={pelak} alt="پلاک خودرو" className="w-64 h-auto" />
                </div>
                {selectedPlate.violations.length > 0 ? (
                  <div className="overflow-x-auto mb-4">
                    <table className="min-w-full border-collapse">
                      <thead>
                        <tr className="bg-gray-200 dark:bg-gray-700 text-center">
                          <th className="border border-gray-300 p-2">تاریخ</th>
                          <th className="border border-gray-300 p-2">نوع خلافی</th>
                          <th className="border border-gray-300 p-2">مقدار (تومان)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedPlate.violations.map((violation, idx) => (
                          <tr key={idx} className="text-center hover:bg-gray-100 dark:hover:bg-gray-600">
                            <td className="border border-gray-300 p-2">{violation.date}</td>
                            <td className="border border-gray-300 p-2">{violation.type}</td>
                            <td className="border border-gray-300 p-2">{violation.amount.toLocaleString()}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="mb-4">خلافی‌ای ثبت نشده است.</p>
                )}
              </>
            )}
            <div className="flex justify-center">
              <button
                onClick={handleCloseModal}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                بستن
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CarPlateHistory;