import { useState, useEffect } from "react";
import Header from "../Header/Header";
import ReactPaginate from 'react-paginate';
import { faCar, faTrafficLight, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import noDataImage from '../../img/no-data.png';

function KhalafiHistory() {
  const [loading, setLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const itemsPerPage = 10;

  useEffect(() => {
    setLoading(true);
    // شبیه‌سازی دریافت داده از سرور
    setTimeout(() => {
      // نمونه داده: اطلاعات هر پلاک به همراه لیست خلافی‌ها
      setHistoryList([
        {
          plateNumber: "12A34567",
          registrationDate: "1401/01/01",
          totalAmount: 600000,
          violations: [
            {
              date: "1396/11/24 - 11:11",
              type: "توقف دوبله در معابر",
              amount: 600000,
              description: "الصاقی"
            }
          ]
        },
        {
          plateNumber: "98B76543",
          registrationDate: "1400/05/12",
          totalAmount: 1200000,
          violations: [
            {
              date: "1398/07/15 - 14:30",
              type: "سرعت غیرمجاز",
              amount: 500000,
              description: "شناسایی دوربین"
            },
            {
              date: "1399/03/22 - 09:15",
              type: "پارک غیرمجاز",
              amount: 700000,
              description: "عدم رعایت ایستگاه پارک"
            }
          ]
        }
      ]);
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(historyList.length / itemsPerPage));
  }, [historyList]);

  const startIndex = currentPage * itemsPerPage;
  const subset = historyList.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleDelete = (plateNumber) => {
    setHistoryList(prevList => prevList.filter(item => item.plateNumber !== plateNumber));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  return (
    <>
      <Header />
      <section className="dark:bg-stone-900 dark:text-gray-100 h-screen">
        <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52'>
          <div className="md:col-span-7 md:p-4 rounded-md">
            <span className='mr-4 text-green-800 dark:text-green-200 text-lg ml-2'>
              <FontAwesomeIcon icon={faTrafficLight} />
            </span>
            <h5 className="inline">تاریخچه استعلام خلافی‌های پلاک</h5>
            <div className="flex justify-end">
              {historyList.length > itemsPerPage && (
                <ReactPaginate
                  pageCount={totalPages}
                  onPageChange={handlePageChange}
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  containerClassName={"pagination-container"}
                  pageLinkClassName={"page-link"}
                  activeLinkClassName={"active"}
                />
              )}
            </div>
            <div className="news-card grid grid-cols-1 gap-x-4 mt-2 px-2 overflow-y-auto">
              {loading ? (
                <p className='text-lg mt-2 pr-6'>در حال بارگذاری...</p>
              ) : historyList.length === 0 ? (
                <div className="flex flex-col items-center py-10">
                  <img src={noDataImage} alt="داده‌ای وجود ندارد" className="w-32 h-32 mb-4" />
                  <p className="text-gray-500 dark:text-gray-400 text-lg">داده‌ای برای نمایش وجود ندارد.</p>
                </div>
              ) : (
                subset.map((item, id) => (
                  <div key={id} className="w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 border rounded-md mb-3 p-3">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col">
                        <p className="text-sm">شماره پلاک: {item.plateNumber}</p>
                        <p className="text-sm">تاریخ ثبت: {item.registrationDate}</p>
                        <p className="text-sm">تعداد خلافی: {item.violations.length}</p>
                        <p className="text-sm">کل مبلغ: {item.totalAmount.toLocaleString()} تومان</p>
                      </div>
                      <div className="flex items-center gap-x-1">
                        <button onClick={() => { setSelectedItem(item); setShowModal(true); }}
                          className="py-1 px-2 bg-green-500 text-white rounded hover:bg-blue-700">
                          مشاهده جزئیات
                        </button>
                        <button onClick={() => handleDelete(item.plateNumber)}
                          className="py-1 px-2 bg-red-500 text-white rounded hover:bg-red-700">
                          حذف
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </section>

      {showModal && selectedItem && (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-white dark:bg-stone-900 dark:text-white bg-opacity-100 p-4">
    {/* دکمه بستن مدال در گوشه */}
    <div className="absolute top-4 right-3">
      <button
        onClick={handleCloseModal}
        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded justify-start items-center"
      >
       x
      </button>
    </div>
    {/* محتوای اصلی مدال */}
    <div className="dark:bg-stone-900 w-full max-w-7xl h-[90vh] rounded-lg shadow-lg flex overflow-hidden ">
      {/* سمت چپ: جدول خلافی‌ها */}
      <div className="w-1/2 p-4 border-r border-gray-300 overflow-y-auto">
        <h2 className="text-xl font-bold mb-4 text-center bg-light-green p-2 text-white rounded">
          جدول خلافی‌ها
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse">
            <thead>
              <tr className="bg-light-green text-white text-center">
                <th className="border border-gray-300 p-4">تاریخ</th>
                <th className="border border-gray-300 p-4">نوع خلافی</th>
                <th className="border border-gray-300 p-4">مقدار (تومان)</th>
              </tr>
            </thead>
            <tbody>
              {selectedItem.violations.map((violation, index) => (
                <tr key={index} className="hover:bg-gray-200 text-center">
                  <td className="border border-gray-300 p-4">{violation.date}</td>
                  <td className="border border-gray-300 p-4">{violation.type}</td>
                  <td className="border border-gray-300 p-4">
                    {violation.amount.toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* سمت راست: باکس اطلاعات پلاک و دکمه پرداخت */}
      <div className="w-1/2 p-4 flex flex-col justify-between">
        <div className="dark:bg-stone-900 p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-2 text-center">جزئیات پلاک</h2>
          <p className="mb-2">شماره پلاک: {selectedItem.plateNumber}</p>
          <p className="mb-2">تاریخ ثبت: {selectedItem.registrationDate}</p>
          <p className="mb-2">
            کل مبلغ خلافی: {selectedItem.totalAmount.toLocaleString()} تومان
          </p>
        </div>
        <button className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-6 rounded text-xl">
          پرداخت
        </button>
      </div>
    </div>
  </div>
)}

    </>
  );
}

export default KhalafiHistory;
