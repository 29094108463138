import { useState, useEffect } from "react";
import Header from "../Header/Header";
import ReactPaginate from 'react-paginate';
import { faPassport, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import passportImage from '../../img/passport.png'; // تصویر گذرنامه
import noDataImage from '../../img/no-data.png';

function PassportHistory() {
  const [loading, setLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const itemsPerPage = 10;

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setHistoryList([{
        passportSerial: "28490000011111111111",
        passportNumber: "E88888888",
        issueDate: "1401/01/01",
        validityDate: "1405/01/01",
        passportStatus: "معتبر",
        
      }]);
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(historyList.length / itemsPerPage));
  }, [historyList]);

  const startIndex = currentPage * itemsPerPage;
  const subset = historyList.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleDelete = (passportNumber) => {
    setHistoryList((prevList) => prevList.filter(item => item.passportNumber !== passportNumber));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  return (
    <>
      <Header />
      <section className="dark:bg-stone-900 dark:text-gray-100 h-screen">
        <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52'>
          <div className="md:col-span-7 md:p-4 rounded-md">
            <span className='mr-4 text-green-800 dark:text-green-200 text-lg ml-2'>
              <FontAwesomeIcon icon={faPassport} />
            </span>
            <h5 className="inline">تاریخچه استعلام‌های گذرنامه</h5>
            <div className="flex justify-end">
              {historyList.length > itemsPerPage && (
                <ReactPaginate
                  pageCount={totalPages}
                  onPageChange={handlePageChange}
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  containerClassName={"pagination-container"}
                  pageLinkClassName={"page-link"}
                  activeLinkClassName={"active"}
                />
              )}
            </div>
            <div className="news-card grid grid-cols-1 gap-x-4 mt-2 px-2 overflow-y-auto">
              {loading ? (
                <p className='text-lg mt-2 pr-6'>در حال بارگذاری...</p>
              ) : historyList.length === 0 ? (
                <div className="flex flex-col items-center py-10">
                  <img src={noDataImage} alt="داده‌ای وجود ندارد" className="w-32 h-32 mb-4" />
                  <p className="text-gray-500 dark:text-gray-400 text-lg">داده‌ای برای نمایش وجود ندارد.</p>
                </div>
              ) : (
                subset.map((item, id) => (
                  <div key={id} className="relative w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3w-full bg-gray-100 dark:bg-gray-600 hover:bg-blue-200 border rounded-md mb-3 p-3">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col">
                        <p className="text-sm">شماره گذرنامه: {item.passportNumber}</p>
                        <p className="text-sm">سریال گذرنامه: {item.passportSerial}</p>
                        <p className="text-sm">تاریخ صدور: {item.issueDate}</p>
                        <p className="text-sm">تاریخ اعتبار: {item.validityDate}</p>
                        <p className="text-sm">وضعیت: <span className="text-green-500">{item.passportStatus}</span></p>
                      </div>
                      <div className="flex items-center gap-x-1">
                        {/* <button onClick={() => { setSelectedItem(item); setShowModal(true); }}
                          className="py-1 px-2 bg-green-500 text-white rounded hover:bg-blue-700">
                          مشاهده جزئیات
                        </button> */}
                        <button onClick={() => handleDelete(item.passportNumber)}
                          className="py-1 px-2 bg-red-500 text-white rounded hover:bg-red-700">
                          حذف
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </section>

      {showModal && selectedItem && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-white bg-opacity-100">
          <div className="p-4 w-1/2">
            <div className="container">
              <div className="card bg-cover bg-center h-screen border-radius"
                style={{ backgroundImage: `url(${passportImage})` }}>
                <div className="front">
                  <div className="flex flex-col items-center mt-3 ">
                    <p>شماره گذرنامه: {selectedItem.passportNumber}</p>
                    <p>سریال گذرنامه: {selectedItem.passportSerial}</p>
                    <p>تاریخ صدور: {selectedItem.issueDate}</p>
                    <p>تاریخ اعتبار: {selectedItem.validityDate}</p>
                    <p>وضعیت: {selectedItem.passportStatus}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <button onClick={handleCloseModal} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-5">
                انصراف
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PassportHistory;
