import { faCar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Header from "../Header/Header";
import pelak from '../../img/pelak.png';

const CaridChecker = () => {
    const [nationalId, setNationalId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [plateImage, setPlateImage] = useState(null);
    const [violations, setViolations] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleNationalIdChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        setNationalId(value);
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        setPhoneNumber(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');
        setMessage('');

        if (nationalId.length !== 10 || phoneNumber.length !== 11) {
            setError('کد ملی باید 10 رقم و شماره موبایل باید 11 رقم باشد.');
            return;
        }

        setLoading(true);
        
        setTimeout(() => {
            setPlateImage(pelak);
            setViolations([
                { date: '1402/01/01', type: 'سرعت غیرمجاز', amount: 200000 },
                { date: '1402/02/15', type: 'پارک غیرمجاز', amount: 100000 },
                { date: '1402/03/10', type: 'کمربند ایمنی', amount: 50000 },
            ]);
            setMessage('اطلاعات با موفقیت دریافت شد!');
            setLoading(false);
        }, 1000);
    };

    return (
        <>
            <Header />
            <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen overflow-y-auto">
                <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52'>
                    <div className="md:col-span-7 md:p-4 rounded-md">
                        <span className='inline-block text-green-800 dark:text-green-200 text-lg ml-2'>
                            <FontAwesomeIcon icon={faCar} />
                        </span>
                        <h5 className="inline-flex items-center">استعلام پلاک خودرو</h5>
                        <h4 className="flex items-center justify-center">لطفا شماره موبایل و کد ملی را وارد کنید.</h4>
                        
                        <form className="flex flex-col items-center mt-4" onSubmit={handleSubmit}>
                            <input
                                type="text"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                placeholder="شماره موبایل (09123456789)"
                                maxLength={11}
                                className='border border-gray-300 rounded-md p-2 w-72 dark:bg-gray-700 dark:text-white mb-2'
                            />
                            <input
                                type="text"
                                value={nationalId}
                                onChange={handleNationalIdChange}
                                placeholder="کد ملی (0023315498)"
                                maxLength={10}
                                className='border border-gray-300 rounded-md p-2 w-72 dark:bg-gray-700 dark:text-white mb-2'
                            />
                            <button type="submit" className='py-2 px-4 text-white bg-light-green rounded w-72 mt-2'>
                                {loading ? 'در حال بررسی...' : 'ارسال'}
                            </button>
                            {error && <div className="error text-red-500 mt-2">{error}</div>}
                            {message && <div className="message text-green-500 mt-2">{message}</div>}
                        </form>
                        <hr className='mt-4' />
                    </div>
                </div>
            </section>
        </>
    );
};

export default CaridChecker;
