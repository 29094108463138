import { useState } from 'react';
import Header from "../Header/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import kartmeli from '../../img/idcard.jpg';
import image2 from '../../img/account-9d64c1a4.svg';

function Caridchecker() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nationalId, setNationalId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');

  // اعتبارسنجی شماره تلفن
  const validatePhoneNumber = (phone) => {
    if (!/^\d{11}$/.test(phone)) return 'شماره تلفن باید 11 رقم باشد';
    return null;
  };

  // اعتبارسنجی کد ملی
  const validateNationalId = (id) => {
    if (!/^\d{10}$/.test(id)) return 'کد ملی باید 10 رقم باشد';
    return null;
  };

  // ارسال درخواست به بک‌اند
  const handleSubmit = async (e) => {
    e.preventDefault();
    const phoneError = validatePhoneNumber(phoneNumber);
    const idError = validateNationalId(nationalId);

    if (phoneError || idError) {
      setError(phoneError || idError);
      return;
    }

    setLoading(true);
    setError(null);
    setMessage('');

    try {
      // ارسال داده‌ها به بک‌اند
      const response = await fetch('/api/passport-check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber, nationalId }),
      });

      if (response.ok) {
        throw new Error('خطا در ارتباط با سرور');
      }

      // نمایش پیام به کاربر
      setMessage('ورودی دریافت شد. منتظر نتیجه استعلام در تاریخچه باشید.');
    } catch (err) {
      setError(err.message || 'خطا در ارسال داده‌ها');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen overflow-auto">
        <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-x-hidden'>
          <div className="md:col-span-7 md:p-4 rounded-md dark:bg-stone-900">
            <span className='inline-block place-content-center mr-4 text-green-800 dark:text-green-200 text-lg ml-2'>
              <FontAwesomeIcon icon={faIdCard} />
            </span>
            <h5 className="inline-flex items-center">استعلام گذرنامه</h5>
            <h4 className="flex items-center justify-center">لطفا شماره تلفن و کد ملی را وارد کنید.</h4>
            <form onSubmit={handleSubmit} className="flex flex-col items-center mt-3 space-y-4">
  <div className="form-group w-full max-w-xs">
    <label className="block mb-1">شماره تلفن:</label>
    <input
      type="text"
      value={phoneNumber}
      onChange={(e) => setPhoneNumber(e.target.value)}
      maxLength="11"
      placeholder="نمونه ورودی: 09123456789"
      className="border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-2 w-full dark:bg-gray-700 dark:text-white"
    />
  </div>
  <div className="form-group w-full max-w-xs">
    <label className="block mb-1">کد ملی:</label>
    <input
      type="text"
      value={nationalId}
      onChange={(e) => setNationalId(e.target.value)}
      maxLength="10"
      placeholder="کد ملی"
      className="border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-2 w-full dark:bg-gray-700 dark:text-white"
    />
  </div>
  <button
    type="submit"
    disabled={loading}
    className="py-2 px-4 w-full max-w-xs text-white hover:bg-yellow bg-light-green rounded cursor-pointer"
  >
    {loading ? 'در حال بررسی...' : 'ارسال'}
  </button>
  {error && <div className="error text-red-500 mt-2">{error}</div>}
  {message && <div className="message text-green-500 mt-2">{message}</div>}
</form>

            <hr />
          </div>
        </div>
      </section>
    </>
  );
}

export default Caridchecker;
